/*** COLOR CODES 3 **/
/**
 * arches - Global style system focused on utility classes
 * @version v4.1.4x
 * @link https://github.com/ACC-Style/Arches/
 */
/**  Build Comment: including file /setup/__preheader.scss  **/
/** SCSS DOC: _preheader.scss **/
/** SCSS DOC: _helpers.scss **/
/** SCSS DOC: _utility-css.vars.scss **/
/**  Build Comment: This Style Sheet should only be used as an augmenting stylesheet  **/
/**  Build Comment: Set Active Class  **/
/**  No Brands Are Used for small project that need to be included with a larger base style sheet.    *********************************/
/**  Colors  **/
/** SCSS DOC: __brand.none.scss **/
/** SCSS DOC: _colors.scss **/
/** Setting link color variables in scss **/
/*

Link Colors: Light

Link colors of a lighter shade in all of its states, default, hover, visited. Typically used to enhance visibility on dark backgrounds.

$link-color-dark			- #126657; Link Color dark			
$link-color-dark--hover	- #051f1a; Link Color dark--hover	
$link-color-dark--visited	- #42665f; Link Color dark--visited	

Weight:2

Styleguide DesignBase.Colors.LinksLight
 */
/*

Link Colors

Link colors  in all of its states, default, hover, visited.


$link-color-light			- #8cebda; Link Color light			
$link-color-light--hover	- #e8fbf8; Link Color light--hover	
$link-color-light--visited	- #84afa7; Link Color light--visited	
$link-color-dark			- #126657; Link Color dark			
$link-color-dark--hover	- #051f1a; Link Color dark--hover	
$link-color-dark--visited	- #42665f; Link Color dark--visited	

Weight:1

Styleguide DesignBase.Colors.Links
 */
/**  Build Comment: Start with No Branding 4.1.4x This is for an augmenting color sheets that don't require a brand  **/
/** SCSS DOC: __var.output.scss **/
/** This needs to run later then brand **/
/** SCSS DOC: _setup.none.scss **/
/**  Build Comment: social Color Stylesheet  **/
.c_twitter {
  color: #000 !important;
}

.br_twitter {
  border-color: #000 !important;
}

.bg_twitter,
.twitter {
  background-color: #000 !important;
}

.c_facebook {
  color: #3b5998 !important;
}

.br_facebook {
  border-color: #3b5998 !important;
}

.bg_facebook,
.facebook {
  background-color: #3b5998 !important;
}

.c_linkedin {
  color: #0077b5 !important;
}

.br_linkedin {
  border-color: #0077b5 !important;
}

.bg_linkedin,
.linkedin {
  background-color: #0077b5 !important;
}

.c_apple-store {
  color: #ea4cc0 !important;
}

.br_apple-store {
  border-color: #ea4cc0 !important;
}

.bg_apple-store,
.apple-store {
  background-color: #ea4cc0 !important;
}

.c_google-play {
  color: #ef6c00 !important;
}

.br_google-play {
  border-color: #ef6c00 !important;
}

.bg_google-play,
.google-play {
  background-color: #ef6c00 !important;
}

.c_youtube {
  color: #bb0000 !important;
}

.br_youtube {
  border-color: #bb0000 !important;
}

.bg_youtube,
.youtube {
  background-color: #bb0000 !important;
}

.c_instagram {
  color: #8134af !important;
}

.br_instagram {
  border-color: #8134af !important;
}

.bg_instagram,
.instagram {
  background-color: #8134af !important;
}

/** SCSS DOC: __globalshame_uc.scss **/